class ADTS {
    /**
     * @param {Uint8Array} data 
     * @param {number} offset 
     * @returns {boolean}
     */
    static isHeaderPattern(data, offset) {
        return data[offset] === 0xff && (data[offset + 1] & 0xf6) === 0xf0;
    }

    /**
     * @param {Uint8Array} data 
     * @param {number} offset 
     * @returns {boolean}
     */
    static isHeader(data, offset) {
        return offset + 1 < data.length && ADTS.isHeaderPattern(data, offset);
    }

    /**
     * @param {Uint8Array} data 
     * @param {number} offset 
     * @returns {number}
     */
    static getHeaderLength(data, offset) {
        return data[offset + 1] & 0x01 ? 7 : 9;
    }

    /**
     * @param {Uint8Array} data 
     * @param {number} offset 
     * @returns {number}
     */
    static getFullFrameLength(data, offset) {
        return (
            ((data[offset + 3] & 0x03) << 11) |
            (data[offset + 4] << 3) |
            ((data[offset + 5] & 0xe0) >>> 5)
        );
    }

    /**
     * Source for probe info - https://wiki.multimedia.cx/index.php?title=ADTS
     * 
     * @param {Uint8Array} data 
     * @param {number} offset 
     * @returns {boolean}
     */
    static probe(data, offset) {
        if (ADTS.isHeader(data, offset)) {
            // ADTS header length
            const headerLength = ADTS.getHeaderLength(data, offset);
            if (offset + headerLength >= data.length) {
                return false;
            }
            // ADTS frame Length
            const frameLength = ADTS.getFullFrameLength(data, offset);
            if (frameLength <= headerLength) {
                return false;
            }

            const newOffset = offset + frameLength;
            return newOffset === data.length || ADTS.isHeader(data, newOffset);
        }
        return false;
    }
}

export default ADTS;
